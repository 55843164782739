.font {
  font-family: "Barlow", sans-serif;
}

.fontlight {
  font-family: "Barlow", sans-serif;
}

.glasseffect {
  backdrop-filter: blur(2px) saturate(200%);
  -webkit-backdrop-filter: blur(2px) saturate(200%);
  background-color: rgba(7, 11, 25, 0.56);
  border-radius: 12px;
}

.glasseffecttop {
  backdrop-filter: blur(2px) saturate(200%);
  -webkit-backdrop-filter: blur(2px) saturate(200%);
  background-color: rgba(0, 0, 0, 0.62);
  border-radius: 12px;
}

video {
  visibility: visible;
}

.bannerText {
  display: block;
}

@media only screen and (min-width: 768px) {
  video {
    visibility: hidden;
  }

  .bannerText {
    display: block;
  }

  .bgclass {
    background-image: url(/src/Assets/bg.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media only screen and (max-width: 600px) {
  .bgclass {
    background-color: #1f2f56;
  }
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
